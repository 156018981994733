// @ts-strict-ignore
import React from 'react';
import { type CampaignResponse, type PriceRule } from 'bb/campaigns/types';
import { isFixedOrRelative, isPercentage } from 'bb/campaigns/utils';
import {
    usePeriod,
    useDaysPeriod,
    useTranslation,
    type TextProps
} from 'bb/i18n';
import { useFormatHandler } from 'bb/i18n/useFormatAmount';
import { type PolymorphicComponent } from 'bb/ui';
import { getDateFromNow } from 'bb/utils';
import { DiscountText } from './DiscountText';

export type PriceRuleTextProps<TElementType extends React.ElementType = 'p'> =
    PolymorphicComponent<
        TElementType,
        {
            priceRule?: PriceRule | CampaignResponse['priceRules'][number];
            dataOptimizelyId?: string;
            daysFromNow?: boolean;
            nrOfDays?: number;
            hourLimit?: number;
            numberOfPeriodsGivenWhenApplied?: number | null;
            displayPeriodInMonths?: boolean;
        } & TextProps<TElementType>
    >;

export const PriceRuleText = ((props: PriceRuleTextProps<'p'>) => {
    const {
        priceRule,
        values,
        daysFromNow,
        nrOfDays = 0,
        hourLimit,
        displayPeriodInMonths = false,
        numberOfPeriodsGivenWhenApplied,
        t: textKey,
        ...restProps
    } = props;
    const {
        lifeTimeInDays: priceRuleLifeTime,
        productDiscount,
        type
    } = priceRule || {};

    const { t } = useTranslation(['registration', 'common', 'subscription']);

    // Compute lifetime in days, if prepaid lifeTimeInDays in pricerule will be wrong
    const lifeTimeInDays =
        numberOfPeriodsGivenWhenApplied && numberOfPeriodsGivenWhenApplied > 1
            ? numberOfPeriodsGivenWhenApplied * 30
            : priceRuleLifeTime;

    // Determine the period format (months vs days)
    const period = displayPeriodInMonths
        ? usePeriod({ days: nrOfDays })
        : useDaysPeriod(nrOfDays);

    // Determine the discount period (months vs days)
    const discountPeriod = displayPeriodInMonths
        ? usePeriod({ days: lifeTimeInDays })
        : useDaysPeriod(lifeTimeInDays);

    const campaignEndDate =
        daysFromNow && lifeTimeInDays
            ? t('common:date', {
                  date: getDateFromNow(lifeTimeInDays + nrOfDays)
              })
            : '';

    const formatAmount = useFormatHandler();

    const discount = isFixedOrRelative(type)
        ? formatAmount(productDiscount)
        : isPercentage(type)
          ? t('common:percentage', { amount: productDiscount })
          : '';

    const additionalValues = {
        ...values,
        discountPeriod: discountPeriod?.period,
        period: period?.period,
        periodLength: period?.periodValue,
        campaignEndDate,
        hourLimit,
        discount
    };

    return (
        <DiscountText
            values={additionalValues}
            {...restProps}
            t={type ? `${textKey}-${type}` : textKey}
        />
    );
}) as <TElementType extends React.ElementType = 'p'>(
    props: PriceRuleTextProps<TElementType>
) => JSX.Element;
